$border-color_1: #2dade3;

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
@keyframes prixClipFix {
	0% {
		clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
	}
	25% {
		clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
	}
	50% {
		clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
	}
	75% {
		clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
	}
	100% {
		clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
	}
}
.wrapper {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}
.loaderWrapper {
	position: absolute;
	display: flex;
	width: 100vw;
	height: 100vh;
	background: white;
	z-index: 10000;
}
.loader {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	position: relative;
	animation: rotate 1.5s linear infinite;
	margin: auto;
	&::before {
		content: "";
		box-sizing: border-box;
		position: absolute;
		inset: 0px;
		border-radius: 50%;
		border: 5px solid #163258;
		animation: prixClipFix 2s linear infinite;
	}
	&::after {
		content: "";
		box-sizing: border-box;
		position: absolute;
		inset: 0px;
		border-radius: 50%;
		border: 5px solid #163258;
		animation: prixClipFix 2s linear infinite;
		border-color: $border-color_1;
		animation: prixClipFix 2s linear infinite, rotate 0.5s linear infinite reverse;
		inset: 6px;
	}
}
