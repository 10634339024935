$background-color_1: #fff;

.containerTwoBlocks {
	display: flex;
	flex-direction: row;
}
.itemTwoBlocks {
	flex: 1 1 0;
	width: 45%;
	margin-right: 25px;
	margin-bottom: 50px;
	padding: 30px;
	background-color: $background-color_1;
	border-radius: 12px;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 8px 24px rgba(0, 0, 0, 0.08);
	&:last-child {
		margin-right: 0;
	}
	p {
		margin: 0px;
	}
}
.itemOneBlock {
	margin-bottom: 50px;
	padding: 30px;
	background-color: $background-color_1;
	border-radius: 12px;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 8px 24px rgba(0, 0, 0, 0.08);
}
h3 {
	margin: 0px;
}
.commentWrapper {
	margin-bottom: 124px;
}
@media screen and (max-width: 550px) {
	.containerTwoBlocks {
		display: flex;
		flex-direction: column;
	}
	.itemTwoBlocks {
		width: 100%;
	}
}
