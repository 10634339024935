$color_1: #e64646;
$background-color_1: #fff;

@keyframes zoom {
	from {
		transform: scale(0.5);
		opacity: 0;
	}
	to {
		transform: scale(1);
		opacity: 1;
	}
}
.input_wrapper {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	height: 45px;
	margin-bottom: 25px;
	padding: 10px;
	background: #fff;
	border: 1px solid #A3A3A3;
	border-radius: 30px;
	transition: border 0.5s;
	&:focus-within {
		border: 1px solid #2787f5;
		.input_icon {
			path {
				fill: #2787f5;
			}
		}
	}
	>div {
		height: 20px;
	}
}
.input_wrapper_error {
	border: 1px solid #e64646;
	&:focus-within {
		.input_icon {
			path {
				fill: #e64646;
			}
		}
		& { 
			border: 1px solid #e64646;
		}
	}
}
.input {
	width: 100%;
	font-size: 16px;
	background: #fff;
	border: none;
	outline: none;
	margin-left: 5px;
}
.input_password {
	width: 90%;
}
.input_icon {
	width: 20px;
	height: 20px;
	path {
		transition: fill 0.5s;
		fill: #939393;
	}
}
.button_eye {
	position: absolute;
	top: 7px;
	right: 10px;
	border: none;
	outline: none;
	cursor: pointer;
	background-color: $background-color_1;
	svg {
		path {
			transition: stroke 0.5s;
			stroke: #b2b2b2;
		}
		circle {
			transition: stroke 0.5s;
			stroke: #b2b2b2;
		}
	}
	&:hover {
		svg {
			path {
				stroke: #939393;
			}
			circle {
				stroke: #939393;
			}
		}
	}
}
.error_message {
	position: absolute;
	top: 110%;
	width: 95%;
	margin: 0px;
	color: $color_1;
	font-size: 14px;
	text-align: center;
	animation: zoom 0.5s;
}
@media screen and (max-width: 400px) {
	.input_wrapper {
		height: 35px;
	}
	.input {
		font-size: 14px;
	}
	.button_eye {
		top: 3px;
		padding: 0.07em 0em;
	}
}
@media screen and (max-width: 420px) {
	.error_message {
		margin-bottom: 10px;
	}
}
@media screen and (max-width: 300px) {
	.error_message {
		font-size: 10px;
	}
}
