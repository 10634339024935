$color_1: #2787f5;

@keyframes fa-spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(359deg);
	}
}
.loader {
	width: 20px;
	height: 20px;
	color: $color_1;
	animation: fa-spin 2s infinite linear;
	fill: #2787f5;
}
