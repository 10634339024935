.modal_title {
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
}

.input_title {
  margin: 10px 0px;
  font-size: 18px;
}

.button_wrapper {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
}