$color_1: #2787f5;
$color_2: rgb(88, 156, 236);
$color_3: #333;
$color_4: var(--md-default-fg-color--light);

@keyframes imageHide {
	from {
		transform: rotate(0deg) scale(1);
		opacity: 1;
	}
	to {
		transform: rotate(5deg) scale(0.5);
		opacity: 0;
	}
}
@keyframes imageShow {
	from {
		transform: rotate(5deg) scale(0.5);
		opacity: 0;
	}
	to {
		transform: rotate(0deg) scale(1);
		opacity: 1;
	}
}
/* @media screen and (max-width: 1075px) {
    .content_inner {
        margin: 0;
        padding: 40px 40px 1px;
        flex-grow: 1;
    }
} */
.container {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
	min-height: calc(100vh - 64px);
	padding-left: 80px;
	background: linear-gradient(90deg, #eef1fa 70%, #dae0ff);
}
.content {
	flex: 2.5 1;
	overflow: hidden;
	scroll-padding-top: 51.2rem;
}
.imageContainer {
	position: -webkit-sticky;
	position: sticky;
	top: 64px;
	flex: 1 3 0;
	align-self: flex-start;
	min-height: calc(100vh - 64px);
}
.imageContainerUnionState {
	padding-top: 5%;
	padding-right: 10px;
	position: -webkit-sticky;
	position: sticky;
	top: 64px;
	flex: 1 3 0;
	align-self: flex-start;
	min-height: calc(100vh - 64px);
}
.imageEmblem {
	max-width: 100%;
	height: auto;
}
.imageHide {
	animation: imageHide 0.6s;
}
.imageShow {
	animation: imageShow 0.6s;
}
.content_inner {
	flex-grow: 1;
	margin: 0;
	padding: 40px 80px 1px;
}
.md_typeset {
	font-size: 18px;
	line-height: 1.6;
	-webkit-print-color-adjust: exact;
	color-adjust: exact;
	a {
		transition: color 125ms;
		color: $color_1;
		word-break: break-word;
		&:before {
			transition: color 125ms;
		}
		&:hover {
			color: $color_2;
		}
	}
	h4 {
		margin: 0px 0px 28px 0px;
		color: $color_3;
		font-weight: 450;
		font-size: 24px;
		line-height: 1.2;
	}
	h2 {
		color: $color_3;
		font-size: 20px;
		line-height: 1.2;
	}
	h3 {
		margin-bottom: 16px;
		font-weight: bold;
		font-size: 18px;
		line-height: 1.2;
	}
	hr {
		display: flow-root;
		border-bottom: 0.05rem solid var(--md-default-fg-color--lightest);
	}
	.footnote {
		color: $color_4;
		font-size: 0.64rem;
	}
}
.text_source {
	font-weight: lighter;
}
.chart_loader_wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 10px;
}
.chart_loader_text {
	width: 40%;
}
.chart_loader_rect {
	width: 100%;
	border-radius: 10px;
}
.anchor_links {
	&:before {
		display: block;
		height: 70px;
		margin: -55px 0 0;
		content: '';
	}
}
.pStyle {
	margin-top: 60px;
}
@media screen and (max-width: 750px) {
	.container {
		padding: 0px 5px;
	}
	.content_inner {
		padding: 40px 5px 1px;
	}
}
@media screen and (max-width: 1560px) {
	.imageEmblem {
		display: contents;
	}
	.imageContainer {
		display: none;
	}
	.imageContainerUnionState {
		display: none;
	}
}
