.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  width: 80px;
  padding-top: 20px;
  overflow: hidden;
  background-color: #242939;
  transition: width 0.3s ease-in-out;
}

.sidebar_open {
  width: 300px;
}

@media screen and (max-width: 750px) {
  .sidebar {
    transform: translateX(-80px);
  }

  .sidebar_open {
    transform: translateX(0px);
  }
}

.sidebar_scrollwrap {
  margin: 0 0.2rem;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overscroll-behavior: contain;
}

/* СТИЛИЗАЦИЯ СКРОЛЛА САЙДБАРА */
.sidebar_scrollwrap::-webkit-scrollbar {
  width: 5px;
  background-color: #f9f9fd00;
}

.sidebar_scrollwrap::-webkit-scrollbar-thumb {
  background-color: #adadad;
  border-radius: 10px;
}

.sidebar_scrollwrap::-webkit-scrollbar-thumb:hover {
  background-color: #1b75bc;
}

.sidebar_scrollwrap::-webkit-scrollbar-track {
  background-color: #f9f9fd;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

/* СТИЛИЗАЦИЯ СКРОЛЛА САЙДБАРА FIREFOX */
.sidebar_scrollwrap {
  scrollbar-color: #adadad #e3e3e6;
  /* «цвет ползунка» «цвет полосы скроллбара» */
  scrollbar-width: thin;
  /* толщина */
}

/* НАВИГАЦИЯ */
.sidebar_nav {
  display: grid;
  grid-template-rows: -webkit-max-content auto 1fr;
  grid-template-rows: max-content auto 1fr;
  height: 100%;
  transition: max-height 0.1s cubic-bezier(0.86, 0, 0.07, 1);
}

.sidebar_nav {
  font-size: 0.7rem;
  line-height: 1.3;
}

.sidebar_top {
  position: relative;
  height: 50px;
  -webkit-perspective: 800px;
  perspective: 800px;
}

.sidebar_logo {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 35px;
  margin-left: 20px;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}

.sidebar_open .sidebar_logo {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.sidebar_toggle {
  position: absolute;
  top: -12px;
  right: 18px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  color: #ccc;
  cursor: pointer;
  -webkit-transition: all 0.3 ease-in-out;
  transition: all 0.3 ease-in-out;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
}

.sidebar_text {
  align-self: center;
  margin: 30px 0px;
  overflow: hidden;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.2;
  -webkit-transform: translateX(16px);
  transform: translateX(16px);
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  -ms-flex-item-align: center;
}

.sidebar_text div {
  width: 300px;
  padding-right: 20px;
  padding-left: 20px;
}

.sidebar_open .sidebar_text {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  pointer-events: all;
}

.sidebar_open .sidebar_toggle {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.sidebar_nav_list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar_nav_item {
  --x-padding: 30px;
  --y-padding: 15px;
  --y-padding-nested: 12px;
  --icon-size: 20px;
  --icon-offset: 15px;
  --item-color: #989eb3;
  --item-bg-color-hover: rgba(0, 0, 0, 0.3);
  --item-bg-color-active: rgba(0, 0, 0, 0.1);
  --icon-color-active: #2787f5;
  width: 300px;
}

.sidebar_nav_item .sidebar_nav_link_active {
  color: #ffffff;
  background: #0000004d;
  opacity: 1;
}

.sidebar_nav_item_nested>.sidebar_nav_link {
  color: --item-color;
}

.sidebar_nav_link {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  min-height: 62px;
  padding: var(--y-padding) calc(var(--x-padding) * 2) var(--y-padding) var(--x-padding);
  color: var(--item-color);
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.sidebar_nav_link:hover {
  color: #fff;
  background: #0000004d;
}

.sidebar_nav_link:active {
  background: #0000001a;
}

.sidebar_nav_item_active>div {
  color: #fff;
  background: #0000004d;
}

.sidebar_nav_item_active>div .sidebar_item_icon>path {
  fill: #0073f6;
}

.sidebar_item_content {
  flex: 1;
  overflow: hidden;
  font-size: 16px;
  white-space: nowrap;
  -webkit-transform: translateX(16px);
  transform: translateX(16px);
  opacity: 0;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out,
    -webkit-transform 0.3s ease-in-out;
}

.sidebar_item_icon {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}

.sidebar_nav_link_active .sidebar_item_icon>path {
  fill: #0073f6;
}

.sidebar_open .sidebar_nav_item_nested .sidebar_nav_link_open.sidebar_nav_link .sidebar_item_icon>path {
  fill: #0073f6;
}

.sidebar_open .sidebar_nav_item_nested .sidebar_nav_link_open.sidebar_nav_link {
  background: #0000004d;
}

.sidebar_open .sidebar_item_content {
  overflow: visible;
  white-space: normal;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}

.sidebar_nav_icon {
  position: absolute;
  top: calc(50% - 4px);
  right: calc(30px * -1);
  display: inline-flex;
  margin-left: auto;
  transition: transform 0.25s !important;
}

.sidebar_nav_item_nested .sidebar_nav_link_open.sidebar_nav_link .sidebar_nav_icon {
  transform: rotate(90deg);
}

.sidebar_nav_icon:after {
  display: inline-block;
  width: 100%;
  height: 100%;
  vertical-align: -0.1rem;
  background-color: currentColor;
  content: '';
  -webkit-mask-image: var(--md-nav-icon--next);
  mask-image: var(--md-nav-icon--next);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
}

.sidebar_nav_primary .sidebar_nav_title .sidebar_nav_icon,
.sidebar_nav_link~.sidebar_nav {
  display: none;
}

.sidebar_nav_link_open~.sidebar_nav,
.sidebar_nav_link_open~.sidebar_nav {
  display: block;
  animation-name: slide;
  animation-duration: 0.5s;
}

@keyframes slide {
  from {
    transform: translateX(-5%);
    opacity: 0;
  }

  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

.sidebar_nav_list_left_border {
  display: none;
}

.sidebar_open .sidebar_nav_list_left_border {
  display: block;
  animation-name: slide;
  animation-duration: 0.5s;
}

.sidebar_open .sidebar_nav_list_left_border a {
  position: relative;
  display: block;
  width: var(--navbar-width-opened);
  padding: var(--y-padding-nested) 0;
  padding-right: var(--x-padding);
  padding-left: calc(var(--x-padding) + var(--icon-size) + var(--icon-offset));
  color: var(--item-color);
  -webkit-transition: background-color var(--duration) var(--easing),
    color var(--duration) var(--easing);
  transition: background-color var(--duration) var(--easing), color var(--duration) var(--easing);
}

.sidebar_open .sidebar_nav_list_left_border a:before {
  position: absolute;
  top: calc(var(--y-padding-nested) + 8px);
  left: calc(var(--x-padding) + var(--icon-size));
  width: 6px;
  height: 6px;
  background-color: #1b75bc;
  border-radius: 50%;
  opacity: 0.7;
  content: '';
}

.sidebar_nav_link_active {
  color: #1b75bc;
}

/* СТИЛИЗАЦИЯ СКРОЛЛА НАВИГАЦИОННОГО ЛИСТА */
.sidebar_nav_list::-webkit-scrollbar {
  width: 5px;
  background-color: #ffffff00;
}

.sidebar_nav_list::-webkit-scrollbar-thumb {
  background-color: #ffffffb4;
  border-radius: 10px;
}

.sidebar_nav_list::-webkit-scrollbar-thumb:hover {
  background-color: #1b75bc;
}

.sidebar_nav_list::-webkit-scrollbar-track {
  background-color: #242939;
  border-radius: 10px;
}

/* FIREFOX */
.sidebar_nav_list {
  scrollbar-color: #adadad #e3e3e6;
  /* «цвет ползунка» «цвет полосы скроллбара» */
  scrollbar-width: thin;
  /* толщина */
}

.sidebar_nav_primary .sidebar_nav_link[for='__toc'] .md_icon:after {
  display: block;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  -webkit-mask-image: var(--md-toc-icon);
  mask-image: var(--md-toc-icon);
}