$color_1: #828282;
$color_2: #333;
$color_3: '#333';
$font-family_1: 'Raleway';
$background-color_1: #fff;

.page_section {
  margin-bottom: 124px;
}

.sub_section {
  margin-bottom: 32px;
}

.notice {
  margin-bottom: 124px;
  color: $color_1;
  font-size: 18px;

  p {
    margin: 0;
  }

  span {
    color: $color_2;
  }
}

.tile {
  padding: 32px;
  background: #fff;
  border-radius: 12px;
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
}

.tile_title {
  margin-bottom: 16px;
  color: $color_2;
  font-weight: 450;
  font-size: 32px;
  line-height: 1.2;
}

.downloadXLSX_link {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.downloadXLSX_link_img {
  -ms-flex: none;
  flex: none;
  margin-right: 0.8em;
  -webkit-box-flex: 0;
}

.footnote {
  padding-left: 1em;
  font-size: 14px;
  line-height: 1.2;
}

.page_text {
  h1 {
    margin: 0px;
    margin-bottom: 40px;
    color: $color_2;
    font-weight: bold;
    font-size: 40px;
    line-height: 1.2;
  }

  p {
    margin-bottom: 32px;
    color: $color_1;
    font-size: 18px;
    line-height: 1.5;
  }

  li {
    margin-bottom: 4px;
    color: $color_1;
    font-size: 18px;
    line-height: 1.5;
  }

  ul {
    margin: 8px 0 8px 30px;
    padding: 0;
  }
}

.containerTwoBlocks {
  display: flex;
  flex-direction: row;
}

.itemTwoBlocks {
  flex: 1 1 0;
  width: 45%;
  margin-right: 25px;
  margin-bottom: 50px;
  padding: 30px;
  background-color: $background-color_1;
  border-radius: 12px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 8px 24px rgba(0, 0, 0, 0.08);
}

.itemOneBlock {
  margin-bottom: 50px;
  padding: 30px;
  background-color: $background-color_1;
  border-radius: 12px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 8px 24px rgba(0, 0, 0, 0.08);
}

.titleStyles {
  text-align: 'center';
  font-size: '24px';
  font-weight: 500;
  color: $color_3;
  margin: 5;
  margin-top: 40;
  font-family: $font-family_1;
}

.wrapperStyles {
  display: 'flex';
  flex-direction: 'column';
  align-items: 'center';
  width: '100%';
  margin-bottom: 50px;
}

.wrapperSeparator {
  margin-top: 120px;
  margin-bottom: 120px;
  border-top: 3px solid rgba(114, 154, 185, 0.5);
}

@media screen and (max-width: 1000px) {
  .tile_title {
    font-size: 22px;
  }
}

@media screen and (max-width: 750px) {
  .tile_title {
    font-size: 18px;
  }

  .notice {
    font-size: 14px;
  }
}

@media screen and (max-width: 550px) {
  .containerTwoBlocks {
    display: flex;
    flex-direction: column;
  }

  .itemTwoBlocks {
    width: 100%;
  }

  .wrapperSeparator {
    margin-top: 0px;
    margin-bottom: 80px;
  }
}