.searchBar {
  position: relative;
  width: 333px;
}

@media screen and (max-width: 750px) {
  .searchBar {
    width: 60%;
  }
}

.searchBarInputs {
  position: relative;
  display: flex;
  width: 100%;
}

.searchBarInputs input {
  width: 100%;
  height: 34px;
  padding: 0 12px;
  padding-right: 32px;
  font-size: 14px;
  font-family: Raleway, Arial, sans-serif;
  text-overflow: ellipsis;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: all 0.3s;
}

.searchBarActive:focus-within input {
  border: 1px solid #2787f5;
}

.searchIcon {
  position: absolute;
  top: calc(50% - 7px);
  right: 12px;
  width: 1em;
  height: 1em;
  font-size: 15px;
  line-height: 1;
}

.searchIcon > path {
  transition: all 0.3s;
  fill: #ccc;
}

.searchBarInputs:focus-within .searchIcon path {
  fill: #2787f5;
}

.searchBarDataResults {
  position: absolute;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  overflow-y: auto;
  background-color: white;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: #00000059 0px 5px 15px;
  opacity: 0;
}

.showResult {
  width: 100%;
  max-height: 180px;
  opacity: 1;
  transition: all 0.5s ease;
}

.searchBarDataResults::-webkit-scrollbar {
  width: 5px;
  background-color: #f9f9fd;
}

.searchBarDataResults::-webkit-scrollbar-thumb {
  background-color: #adadad;
  border-radius: 10px;
}

.searchBarDataResults::-webkit-scrollbar-thumb:hover {
  background-color: #706f6f;
}

.searchBarDataResults::-webkit-scrollbar-track {
  background-color: #f9f9fd;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

.searchBarDataResults .dataItem {
  display: block;
  width: 100%;
  padding: 10px 12px;
  font-size: 14px;
}

.dataItem p {
  margin: 0;
}

.searchBarDataResults a {
  text-decoration: none;
}

.searchBarDataResults a:hover {
  background-color: #eeeeee;
}

#clearBtn {
  cursor: pointer;
}
