.item_block {
    margin-bottom: 50px;
    padding: 30px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 8px 24px rgba(0, 0, 0, 0.08);
}

.page_section {
    margin-bottom: 124px;
}

.sub_section {
    margin-bottom: 32px;
}

.tile {
    padding: 32px;
    background: #fff;
    border-radius: 12px;
    -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
}

.tile_title {
    margin-bottom: 16px;
    color: #333;
    font-weight: 450;
    font-size: 32px;
    line-height: 1.2;
}

.downloadPDF_link {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
}

.downloadPDF_link_img {
    -ms-flex: none;
    flex: none;
    margin-right: 0.8em;
    -webkit-box-flex: 0;
}

@media screen and (max-width: 1000px) {
    .tile_title {
        font-size: 22px;
    }
}

@media screen and (max-width: 750px) {
    .tile_title {
        font-size: 18px;
    }

    .notice {
        font-size: 14px;
    }
}