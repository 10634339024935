.modal_title {
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
}

.buttons_wrapper {
  display: flex;
  justify-content: center;
}
