.dynamic_chart {
  position: relative;
}

.dynamic_chart:fullscreen {
  overflow: auto !important;
}
.dynamic_chart:-ms-fullscreen {
  overflow: auto !important;
}
.dynamic_chart:-webkit-full-screen {
  overflow: auto !important;
}
.dynamic_chart:-moz-full-screen {
  overflow: auto !important;
}

.fullscreen {
  padding: 10px 20px 5px;
  background: #fff;
}

.dynamic_chart_title {
  margin-bottom: 28px;
  font-weight: 500;
  font-size: 24px;
  line-height: 1.2;
}

.dynamic_chart_title_fullscreen {
  margin-top: 40px;
}

.dynamic_chart_main_title {
  margin: 0;
  color: #333;
  font-weight: 500;
  font-size: 24px;
}

.dynamic_chart_subtitle {
  margin-top: 4px;
  color: #828282;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.2;
}

.dynamic_chart_nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 40px;
  border-bottom: 1px solid #d3dff1;
}

.dynamic_chart_tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dynamic_chart_tabs_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  padding: 0 15px;
  color: #333;
  font-size: 18px;
  font-family: 'Raleway';
  border-bottom: 3px solid transparent;
  cursor: pointer;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.active_tab {
  color: #2787f5;
  font-weight: 600;
  border-bottom-color: #2787f5;
}

.filter_buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 33px;
  margin-left: auto;
  padding: 4px 6px;
  background-color: #d4e7fd;
  border-radius: 6px;
}

.filter_button_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  color: #333;
  font-size: 14px;
  line-height: 1.2;
  border-radius: 4px;
  cursor: pointer;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
}

.active_filter {
  background-color: #fff;
}

.dynamic_chart_actions {
  position: absolute;
  right: 20px;
  z-index: 2;
  display: flex;
  gap: 40px;
  align-items: center;
  justify-content: flex-end;
  height: 18px;
  margin-top: 5px;
}

.dynamic_chart_actions_fs {
  position: fixed;
  top: 10px;
  right: 30px;
  z-index: 100;
}

.dynamic_chart_actions svg {
  cursor: pointer;
}

.dynamic_chart_actions svg:hover path {
  fill: #2787f5;
}

.edit_chart {
  width: 18px;
  height: 18px;
}

.dynamic_chart_actions .close_fullscreen:hover path {
  width: 40px;
  height: 40px;
  fill: black;
}

@media screen and (max-width: 960px) {
  .dynamic_chart_nav {
    flex-direction: column;
    padding-bottom: 10px;
  }

  .filter_buttons {
    margin: 0;
    margin-top: 10px;
  }

  .filter_button_item {
    width: 50%;
  }
}

@media screen and (max-width: 750px) {
  .dynamic_chart_main_title {
    font-size: 16px;
  }
}

@media screen and (max-width: 750px) {
  .dynamic_chart_main_title {
    font-size: 16px;
  }

  .dynamic_chart_subtitle {
    font-size: 14px;
  }

  .dynamic_chart_tabs_item {
    height: auto;
    padding: 0px 4px;
    font-size: 12px;
  }

  .filter_button_item {
    padding: 4px;
    font-size: 10px;
  }

  .dynamic_chart_actions_fs {
    top: 10px;
    right: 10px;
  }
}
