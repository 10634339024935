$color_1: #333333;
$color_2: #656565;
$color_3: #ffffff;
$font-family_1: Raleway, sans-serif;
$background-color_1: #eef1fa;

@keyframes imageShow {
	from {
		transform: scale(0.5);
		opacity: 0;
	}
	to {
		transform: scale(1);
		opacity: 1;
	}
}
.error_page {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	background-color: $background-color_1;
}
.error_page_content {
	width: 100%;
	max-width: 626px;
	text-align: center;
	animation: imageShow 0.6s;
	img {
		width: 168px;
		max-width: 100%;
		height: 186px;
	}
	.error_page_title {
		margin-top: 12px;
		margin-bottom: 12px;
		color: $color_1;
		font-weight: bold;
		font-size: 40px;
		font-family: $font-family_1;
		font-style: normal;
		line-height: 48px;
	}
	.error_page_description {
		max-width: 595px;
		margin-bottom: 32px;
		color: $color_2;
		font-weight: normal;
		font-size: 18px;
		font-family: $font-family_1;
		font-style: normal;
		line-height: 27px;
	}
	a {
		display: flex;
		width: calc(100% - 52px);
		margin-right: auto;
		margin-left: auto;
		padding: 12px 20px;
		color: $color_3;
		font-weight: normal;
		font-size: 18px;
		font-family: $font-family_1;
		line-height: 27px;
		text-decoration: none;
		background: #2787f5;
		border-radius: 4px;
		&:hover {
			background: #589cec;
		}
	}
}
