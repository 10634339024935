.header {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 0px 32px;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
  transform: translateY(0px);
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.headerHidden {
  transform: translateY(-100px);
}

.headerButton.headerIcon svg {
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  fill: #5d688b;
}

.headerButton.headerIcon {
  transform: translateX(0px);
  transition: transform 0.22s ease-in;
}

.headerButton:not(.focus-visible) {
  outline: none;
}

.headerButton {
  position: relative;
  z-index: 1;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  transition: opacity 0.25s;
}

.loginImg {
  width: 26px;
  height: 26px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 20px;
  cursor: pointer;
}

.dirImg {
  width: 45px;
  height: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  cursor: pointer;
  opacity: 0.4;
}

.dirImgDisabled {
  width: 45px;
  height: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  pointer-events: none;
}

.dirMainImg {
  width: 45px;
  height: 25px;
  margin-top: 5px;
  margin-bottom: 15px;
  margin-right: 20px;
  cursor: pointer;
  opacity: 0.4;
}

.dirMainImgDisabled {
  width: 45px;
  height: 25px;
  margin-top: 5px;
  margin-bottom: 15px;
  margin-right: 20px;
  pointer-events: none;

}

.dirMainImg:hover, .dirImg:hover {
  transition: 0.8s;
  opacity: 1.0;
}


/* .dirImgDisabled path, .dirMainImgDisabled path {
  fill: #2787f5;
}

.dirMainImg path, .dirImg path {
  fill: #939393;
}

.dirMainImg:hover path, .dirImg:hover path {
  transition: 0.5s;
  fill: #2787f5;
} */

.loginImg path {
  fill: #939393;
}

.loginImg:hover path {
  transition: 0.5s;
  fill: #2787f5;
}

.imageHeaderStyle {
  width: 35px;
  height: 35px;
}

@keyframes slide {
  from {
    transform: translateX(5%);
    opacity: 0;
  }

  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes slide1 {
  from {
    transform: translateX(5%);
    opacity: 0;
  }

  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

@media screen and (min-width: 750px) {
  .header {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 64px;
    padding-right: 32px;
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
  }

  .headerButton.headerIcon {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .dirImg, .dirImgDisabled, .dirMainImg, .dirMainImgDisabled {
    margin-right: 5px;
  }
}

@media screen and (max-width: 500px) {
  .dirImg, .dirImgDisabled {
    width: 30px;
    height: 20px;
  }

  .dirMainImg, .dirMainImgDisabled {
    width: 30px;
    height: 15px;
  }
}

@media screen and (max-width: 450px) {
  .dirImg, .dirImgDisabled {
    margin-right: 1px;
    margin-left: 2px;
  }

  .dirMainImg, .dirMainImgDisabled {
    margin-right: 1px;
  }
}

@media screen and (max-width: 410px) {
  .dirImg, .dirImgDisabled, .dirMainImg, .dirMainImgDisabled {
    margin-left: 30px;
  }
}