.container {
	width: 100%;
	height: 100vh;
	background: linear-gradient(229.01deg, #5DC3EF 0.89%, #3C83B1 27.51%, #2F6998 40.49%, #185585 50.47%, #004071 73.96%);
  }
  
  .wrapper {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	background: linear-gradient(229.01deg, #5DC3EF 0.89%, #3C83B1 27.51%, #2F6998 40.49%, #185585 50.47%, #004071 73.96%);
	min-height: 100vh;
  }
  
  .main {
	width: 500px;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	padding: 50px 40px 44px;
	background: #fff;
	border: 1px solid rgba(10, 10, 10, 0.08);
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 8px 24px rgba(0, 0, 0, 0.08);
	border-radius: 10px;
  }
  
  @media screen and (max-width: 550px) {
	.main {
	  width: 80%;
	}
  }
  
  @media screen and (max-width: 420px) {
	.main {
	  width: 95%;
	  padding: 50px 20px 44px;
	}
  }
  
  @media screen and (max-width: 400px) {
	.main {
	  padding: 35px 20px 5px;
	}
  }
  
  /* дополнительный стиль для размера формы регистрации */
  
  .mainRegister {
	padding: 15px 40px 0px;
  }
  
  @media screen and (max-width: 400px) {
	.mainRegister {
	  padding: 35px 20px 5px;
	}
  }
  
  @media screen and (max-width: 380px) {
	.mainRegister {
	  padding: 5px 20px 0px;
	}
  }
  
  /* ----------------------------------------- */
  
  .button_wrapper {
	margin-top: 40px;
  }
  
  .logo {
	margin-bottom: 72px;
  }
  
  @media screen and (max-width: 450px) {
	.logo {
	  width: 260px
	}
  }
  
  @media screen and (max-width: 350px) {
	.logo {
	  width: 230px
	}
  }
  
  @media screen and (max-width: 300px) {
	.logo {
	  width: 180px
	}
  }
  
  /* стили логотипа для формы регистрации */
  
  .logoRegister {
	margin-bottom: 30px;
  }
  
  @media screen and (max-width: 450px) {
	.logoRegister {
	  width: 260px
	}
  }
  
  @media screen and (max-width: 350px) {
	.logoRegister {
	  width: 230px
	}
  }
  
  @media screen and (max-width: 300px) {
	.logoRegister {
	  width: 180px
	}
  }
  
  /* ----------------------------------------- */
  
  .link {
	display: inline-flex;
	align-items: center;
	margin-top: 24px;
	font-weight: 600;
  }
  
  .link svg {
	margin-right: 7px;
  }
  
  .button {
	width: 100%;
	height: 64px;
	font-family: Raleway, sans-serif;
	font-weight: 500;
	font-style: normal;
	font-size: 20px;
	line-height: 120%;
	border-radius: 50px;
	border: 1px solid #A3A3A3;
	background: white;
	margin-bottom: 40px;
	cursor: pointer;
	color: #1c1c21;
	transition: all 0.2s ease-out;
  }
  
  .additional {
	color: #272727;
	opacity: 0.8;
	height: 45px;
	margin-bottom: 30px;
  }
  
  /* дополнительные стили для кнопок формы регистрации */
  
  .additionalRegister {
	color: #272727;
	opacity: 0.8;
	height: 45px;
	margin-bottom: 20px;
  }
  
  /* ----------------------------------------- */
  
  .buttonForm:disabled,
  .button[disabled] {
	color: #666666;
	background-color: #ffffff;
	opacity: 0.33;
  }
  
  .button:disabled:hover,
  .button[disabled]:hover {
	cursor: not-allowed;
  }
  
  @media screen and (max-width: 500px) {
	.button {
	  font-size: 16px;
	}
  }
  
  @media screen and (max-width: 400px) {
	.button {
	  font-size: 14px;
	  height: 40px;
	}
  }
  
  @media screen and (max-width: 350px) {
	.button {
	  font-size: 12px;
	  height: 30px;
	}
  }
  
  @media screen and (max-width: 300px) {
	.button {
	  font-size: 10px;
	}
  }
  
  .button:hover {
	color: #5885a7;
	border: 1px solid #5885a7;
  }
  
  .formStyle {
	width: 90%;
  }
  
  
  .separator {
	width: 100%;
	text-align: center;
	font-family: Raleway, sans-serif;
	color: #666666;
	border-bottom: 1px solid #666666;
	line-height: 0.1em;
	margin: 10px 0 40px;
	opacity: 0.6;
  }
  
  .separator span {
	background: #fff;
	padding: 0 10px;
  }
  
  .error_message {
	width: 95%;
	margin: 0px;
	margin-bottom: 30px;
	color: #e64646;
	font-size: 14px;
	text-align: center;
	animation: zoom 0.5s;
  }
  
  @media screen and (max-width: 420px) {
	.error_message {
	  margin-bottom: 10px;
	}
  }
  
  @media screen and (max-width: 300px) {
	.error_message {
	  font-size: 10px;
	}
  }
  
  @keyframes zoom {
	from {
	  transform: scale(0.5);
	  opacity: 0;
	}
  
	to {
	  transform: scale(1);
	  opacity: 1;
	}
  }
  
  .confirm_message {
	width: 95%;
	margin: 0px;
	margin-bottom: 30px;
	color: #078c31;
	font-size: 14px;
	text-align: center;
	animation: zoom 0.5s;
  }
  
  @media screen and (max-width: 420px) {
	.confirm_message {
	  margin-bottom: 10px;
	}
  }
  
  @media screen and (max-width: 300px) {
	.confirm_message {
	  font-size: 10px;
	}
  }
  
  @keyframes zoom {
	from {
	  transform: scale(0.5);
	  opacity: 0;
	}
  
	to {
	  transform: scale(1);
	  opacity: 1;
	}
  }