.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  animation-name: appear;
  animation-duration: 300ms;
}

.modal_dialog {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 620px;
  max-height: 95vh;
  margin: 0 20px;
  padding: 40px;
  overflow: hidden;
  text-align: left;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  animation-name: slide-in;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.5s;
}

.modal_body {
  padding: 5px;
}

.modal_body::-webkit-scrollbar {
  width: 7px;
  background-color: #f9f9fd00;
}

.modal_body::-webkit-scrollbar-thumb {
  background-color: #adadad;
  border-radius: 7px;
}

.modal_body::-webkit-scrollbar-thumb:hover {
  background-color: #706f6f;
}

.modal_body::-webkit-scrollbar-track {
  background-color: #dfdbdb;
  border-radius: 7px;
}

/* СКРОЛЛ СТРАНИЦЫ ДЛЯ FIREFOX */
.modal_body {
  scrollbar-color: #adadad #e3e3e6;
  /* «цвет ползунка» «цвет полосы скроллбара» */
  scrollbar-width: thin;
  /* толщина */
}

.modal-footer {
  justify-content: flex-end;
  border-top: 1px solid #dbdbdb;
}

.modal_close {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.modal_close svg {
  width: 25px;
  height: 25px;
}

.modal_body {
  overflow: auto;
}

.modal-title {
  margin: 0;
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slide-in {
  from {
    transform: scale(0.5);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

@media (max-width: 500px) {
  .modal_dialog {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 620px;
    margin: 0px 0px;
    overflow: hidden;
    text-align: left;
    background: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animatetop;
    animation-name: slide-in;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.5s;
  }

  .modal_close {
    position: absolute;
    top: 10px;
    right: 10px;
    bottom: 1rem;
    left: auto;
    padding: 0px;
    font-size: 25px;
    cursor: pointer;
  }
}
