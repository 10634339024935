:root>* {
  --md-code-fg-color: #36464e;
  --md-code-bg-color: #f5f5f5;
  --md-code-hl-color: rgba(255, 255, 0, 0.5);
  --md-code-hl-number-color: #d52a2a;
  --md-code-hl-special-color: #db1457;
  --md-code-hl-function-color: #a846b9;
  --md-code-hl-constant-color: #6e59d9;
  --md-code-hl-keyword-color: #3f6ec6;
  --md-code-hl-string-color: #1c7d4d;
  --md-code-hl-name-color: var(--md-code-fg-color);
  --md-code-hl-operator-color: var(--md-default-fg-color--light);
  --md-code-hl-punctuation-color: var(--md-default-fg-color--light);
  --md-code-hl-comment-color: var(--md-default-fg-color--light);
  --md-code-hl-generic-color: var(--md-default-fg-color--light);
  --md-code-hl-variable-color: var(--md-default-fg-color--light);
  --md-typeset-color: var(--md-default-fg-color);
  --md-typeset-a-color: var(--md-primary-fg-color);
  --md-typeset-mark-color: rgba(255, 255, 0, 0.5);
  --md-typeset-del-color: rgba(245, 80, 61, 0.15);
  --md-typeset-ins-color: rgba(11, 213, 112, 0.15);
  --md-typeset-kbd-color: #fafafa;
  --md-typeset-kbd-accent-color: #fff;
  --md-typeset-kbd-border-color: #b8b8b8;
  --md-admonition-fg-color: var(--md-default-fg-color);
  --md-admonition-bg-color: var(--md-default-bg-color);
  --md-footer-fg-color: #fff;
  --md-footer-fg-color--light: hsla(0, 0%, 100%, 0.7);
  --md-footer-fg-color--lighter: hsla(0, 0%, 100%, 0.3);
  --md-footer-bg-color: rgba(0, 0, 0, 0.87);
  --md-footer-bg-color--dark: rgba(0, 0, 0, 0.32);
}

:root {
  --md-default-fg-color: rgba(0, 0, 0, 0.87);
  --md-default-fg-color--light: rgba(0, 0, 0, 0.54);
  --md-default-fg-color--lighter: rgba(0, 0, 0, 0.32);
  --md-default-fg-color--lightest: rgba(0, 0, 0, 0.07);
  --md-default-bg-color: #fff;
  --md-default-bg-color--light: hsla(0, 0%, 100%, 0.7);
  --md-default-bg-color--lighter: hsla(0, 0%, 100%, 0.3);
  --md-default-bg-color--lightest: hsla(0, 0%, 100%, 0.12);
  --md-primary-fg-color: #4051b5;
  --md-primary-fg-color--light: #5d6cc0;
  --md-primary-fg-color--dark: #303fa1;
  --md-primary-bg-color: #fff;
  --md-primary-bg-color--light: hsla(0, 0%, 100%, 0.7);
  --md-accent-fg-color: #526cfe;
  --md-accent-fg-color--transparent: rgba(83, 108, 254, 0.1);
  --md-accent-bg-color: #fff;
  --md-accent-bg-color--light: hsla(0, 0%, 100%, 0.7);
  --base-font: 'Raleway';
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  src: url('assets/fonts/Raleway/Raleway-Regular.woff2');
}

@font-face {
  font-weight: bold;
  font-family: 'Raleway';
  src: url('assets/fonts/Raleway/Raleway-Bold.woff2');
}

@font-face {
  font-weight: 500;
  font-family: 'Raleway';
  src: url('assets/fonts/Raleway/Raleway-SemiBold.woff2');
}

@font-face {
  font-weight: bold;
  font-family: 'Raleway';
  font-style: italic;
  src: url('assets/fonts/Raleway/Raleway-SemiBoldItalic.woff2');
}

@font-face {
  font-family: 'Raleway';
  font-style: italic;
  src: url('assets/fonts/Raleway/Raleway-MediumItalic.woff2');
}

@font-face {
  font-family: 'Raleway';
  font-style: oblique;
  src: url('assets/fonts/Raleway/Raleway-MediumItalic.woff2');
}

/* СКРОЛЛ СТРАНИЦЫ */
html::-webkit-scrollbar {
  width: 7px;
  background-color: #f9f9fd00;
}

html::-webkit-scrollbar-thumb {
  background-color: #adadad;
  border-radius: 7px;
}

html::-webkit-scrollbar-thumb:hover {
  background-color: #706f6f;
}

html::-webkit-scrollbar-track {
  background-color: #dfdbdb;
  border-radius: 7px;
}

/* СКРОЛЛ СТРАНИЦЫ ДЛЯ FIREFOX */
html,
body {
  scrollbar-color: #adadad #e3e3e6;
  /* «цвет ползунка» «цвет полосы скроллбара» */
  scrollbar-width: thin;
  /* толщина */
}

h1 {
  margin: 0;
  margin-bottom: 40px;
  color: #333;
  font-weight: bold;
  font-size: 40px;
}

@media screen and (max-width: 750px) {
  h1 {
    font-size: 1.5em;
  }
}

#root {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
}

article {
  display: block;
}

*,
:after,
:before {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
}

html {
  height: 100%;
  overflow-x: hidden;
  font-size: 125%;
}

body {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  font-size: 0.5rem;
  font-family: var(--base-font);
  background-color: #fff;
  background-color: var(--md-default-bg-color);
}

body,
code,
input,
kbd,
pre {
  color: var(--md-typeset-color);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

table {
  border-collapse: separate;
  border-collapse: initial;
  border-spacing: 0;
}

thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}

tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

td,
th {
  font-weight: 400;
  vertical-align: top;
}

tr {
  display: table-row;
  vertical-align: inherit;
}

hr {
  display: block;
  box-sizing: initial;
  height: 0.05rem;
  padding: 0;
  overflow: visible;
  border: 0;
}

/* Настройки для Highcharts */

.highcharts-legend-item .highcharts-point {
  width: 19px;
  height: 3px;
  stroke-width: 0;
}

g.highcharts-series,
.highcharts-markers,
.highcharts-point {
  outline: none;
}

g.highcharts-series,
.highcharts-point,
.highcharts-markers,
.highcharts-data-labels {
  transition: opacity 250ms;
}

.highcharts-point {
  stroke-width: 1px;
}

.highcharts-markers {
  stroke: #ffffff;
  stroke-width: 1px;
}

.highcharts-button-box {
  fill: transparent;
}

div,
a,
div[role='button'] {
  -webkit-tap-highlight-color: #00000000;
}

/* Стили для всплывающего окна поп-ап, библиотека sweetalert2 */

.swal2-popup {
  font-size: 16px !important;
  font-family: 'Raleway';
}

.swal2-html-container {
  text-align: left;
}