.commentUi {
  display: flow-root;
  margin: 1.5625em 0;
  padding: 0 0.6rem;
  padding-top: 0;
  overflow: visible;
  overflow: hidden;
  color: var(--md-admonition-fg-color);
  font-size: 0.64rem;
  page-break-inside: avoid;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 0.2rem 0.5rem rgb(0 0 0 / 5%), 0 0.025rem 0.05rem rgb(0 0 0 / 5%);
}

.commentUi .commentUiArrowIcon {
  width: 0.7rem !important;
  height: 0.7rem !important;
  transform: rotate(90deg);
  transition: transform 0.25s !important;
}

.commentUi .commentUiArrowIcon path {
  fill: #000;
}

.commentUiOpen .commentUiArrowIcon {
  transform: rotate(270deg);
}

.commentUi .summary {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 1rem;
  padding: 18px 0px;
  font-weight: bold;
  font-size: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-top-left-radius: 0.1rem;
  border-top-right-radius: 0.1rem;
  cursor: pointer;
}

.commentUi .summary div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.commentUi .summary div p {
  margin: 0 0 0 10px;
}

.commentUi p {
  white-space: pre-wrap;
}