.country_nav_buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 36px;
}

.country_button {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  height: 51px;
  padding: 0 1.3em;
  color: #333;
  font-weight: normal;
  font-size: 18px;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  -webkit-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 8px 24px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 8px 24px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

@media screen and (max-width: 650px) {
  .country_button {
    height: 40px;
    padding: 0 1em;
    font-size: 0.9em;
  }
}

.country_button:hover {
  background-color: #d0dae9;
}

.country_button:active {
  background-color: #c9d3e1;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}

.active {
  background-color: #d4e7fd;
}

.active:hover {
  background-color: #d4e7fd;
}

.country_name {
  --ratio: calc(30 / 20);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.country_name img {
  --width: 30px;
  -ms-flex: none;
  flex: none;
  width: var(--width);
  height: calc(var(--width) / var(--ratio));
  margin-right: 12px;
  -webkit-box-flex: 0;
}

@media screen and (max-width: 750px) {
  .country_nav_buttons {
    justify-content: center;
  }
}
