$color_1: #cf2d2d;
$color_2: #fff;
$font-family_1: 'Raleway';

.chart_loader_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.chart_loader_text {
  width: 40%;
}

.chart_loader_rect {
  width: 100%;
  border-radius: 10px;
}

.chart_error_wrapper {
  position: relative;
}

.chart_error {
  position: absolute;
  top: 20%;
  left: 45%;

  img {
    width: 150px;
  }
}

.error_message {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;

  p {
    &:first-child {
      color: $color_1;
    }
		& { 
			margin: 0;
		}
  }
}

.repeat_request {
  margin-top: 10px;
  padding: 4px 12px;
  color: $color_2;
  font-family: $font-family_1;
  background: #2787f5;
  border: 0;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background: #589cec;
  }

  &:active {
    -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2) inset;
    -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2) inset;
    box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2) inset;
    transform: translateY(2px);
  }
}

.disabled_but {
  background: #c4c4c4;

  &:hover {
    background: #666666;
  }
}

@media screen and (max-width: 750px) {
  .chart_error {
    left: 30%;

    img {
      width: 100px;
    }
  }
}