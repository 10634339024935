.table {
  display: table;
  font-family: 'Open Sans';
}

.table {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 40px;
  overflow: auto;
  font-size: 12px;
  background-color: #fff;
  background-color: var(--md-default-bg-color);
  border-radius: 0.1rem;
  border-radius: 10px;
  box-shadow: 0 0.2rem 0.5rem rgb(0 0 0 / 5%), 0 0 0.05rem rgb(0 0 0 / 10%);
  touch-action: auto;
}

.table {
  display: table;
  font-family: 'Raleway';
}

.table tr {
  transition: background-color 125ms;
}

.table tr:hover {
  background-color: #4343480a;
}

.table th {
  background-color: #434348;
  background-color: var(--chart-black);
}

.table th {
  min-width: 5rem;
  padding: 10px 5px;
  color: rgb(0, 0, 0);
  font-size: 12px;
  vertical-align: middle;
  background-color: #d4e7fd;
  border-right: 1px solid rgba(40, 114, 185, 0.2);
}

.table thead tr:first-child th:first-child {
  border-radius: 10px 0px 0px 0px;
}

.table thead tr:first-child th:last-child {
  border-right: none;
  border-radius: 0px 10px 0px 0px;
}

.table thead tr:last-child th:last-child {
  border-right: none;
}

.table tr:first-child td {
  border-top: 0;
}

.table td:not([class]) {
  font-size: 12px;
  font-family: 'Raleway';
  text-align: right;
}

.table td {
  padding: 10px 10px;
  vertical-align: top;
  border-top: 1px solid rgba(40, 114, 185, 0.2);
  border-right: 1px solid rgba(40, 114, 185, 0.2);
}

.table td:not([align]) {
  text-align: left;
}

.table td:last-child {
  border-right: none;
}

.table + * {
  margin-top: 1.5em;
}

.table img {
  width: 14px;
  margin-right: 8px;
}

@media screen and (max-width: 1075px) {
  .table {
    display: block;
  }
}
