$color_1: #828282;
$color_2: #333;

.page_section {
  margin-bottom: 124px;
}

.sub_section {
  margin-bottom: 32px;
}

.notice {
  margin-bottom: 124px;
  color: $color_1;
  font-size: 18px;

  p {
    margin: 0;
  }

  span {
    color: $color_2;
  }
}

.tile {
  padding: 32px;
  background: #fff;
  border-radius: 12px;
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
}

.tile_title {
  margin-bottom: 16px;
  color: $color_2;
  font-weight: 450;
  font-size: 32px;
  line-height: 1.2;
}

.downloadXLSX_link {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.downloadXLSX_link_img {
  -ms-flex: none;
  flex: none;
  margin-right: 0.8em;
  -webkit-box-flex: 0;
}

.downloadPDF_link {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.downloadPDF_link_img {
  -ms-flex: none;
  flex: none;
  margin-right: 0.8em;
  -webkit-box-flex: 0;
}

.footnote {
  padding-left: 1em;
  font-size: 14px;
  line-height: 1.2;
}

.page_text {
  padding-bottom: 50px;

  h1 {
    margin: 0px;
    margin-bottom: 40px;
    color: $color_2;
    font-weight: bold;
    font-size: 40px;
    line-height: 1.2;
  }

  p {
    margin-bottom: 32px;
    color: $color_1;
    font-size: 18px;
    line-height: 1.5;
  }

  li {
    margin-bottom: 4px;
    color: $color_1;
    font-size: 18px;
    line-height: 1.5;
  }

  ul {
    margin: 8px 0 8px 30px;
    padding: 0;
  }
}

@media screen and (max-width: 1000px) {
  .tile_title {
    font-size: 22px;
  }
}

@media screen and (max-width: 750px) {
  .tile_title {
    font-size: 18px;
  }

  .notice {
    font-size: 14px;
  }
}