$color_1: #ffffff;
$font-family_1: Raleway, sans-serif;

@keyframes imageShow {
	from {
		transform: scale(0.5);
		opacity: 0;
	}
	to {
		transform: scale(1);
		opacity: 1;
	}
}
.page_not_found_wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 30px;
	font-family: $font-family_1;
	text-align: center;
	animation: imageShow 0.6s;
	img {
		width: 300px;
	}
}
.page_not_fount_link_to_home {
	display: flex;
	margin-right: auto;
	margin-left: auto;
	padding: 12px 20px;
	color: $color_1 !important;
	font-weight: normal;
	font-size: 18px;
	font-family: $font-family_1;
	line-height: 27px;
	text-decoration: none;
	background: #2787f5;
	border-radius: 4px;
	&:hover {
		background: #589cec;
	}
}
