$color_1: #fff;
$color_2: #666666;
$color_3: #e64646;
$background-color_1: #eeeeee;
$background-color_2: #dfdbdb;

@keyframes zoom {
	from {
		transform: scale(0.5);
		opacity: 0;
	}
	to {
		transform: scale(1);
		opacity: 1;
	}
}
@keyframes jump {
	from {
		transform: translateY(3px);
	}
	to {
		transform: translateY(0px);
	}
}
.button_wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-width: 50%;
	max-width: 100%;
	max-height: 40px;
	margin-bottom: 25px;
}
.button {
	width: 80%;
	max-height: 40px;
	padding: 10px 20px;
	color: $color_1;
	font-size: 16px;
	background: #2787f5;
	border: 1px solid #fff;
	border-radius: 30px;
	outline: none;
	cursor: pointer;
	transition: background 0.5s;
	&:disabled {
		color: $color_2;
		background-color: $background-color_1;
		&:hover {
			background-color: $background-color_2;
		}
	}
	&:focus {
		border: 1px solid #63b9ba;
	}
	&:hover {
		background: #589cec;
	}
	&:active {
		background: #0f4a8d;
		border: 1px solid #fff;
		animation: jump 0.3s;
	}
}
.button[disabled] {
	color: $color_2;
	background-color: $background-color_1;
	&:hover {
		background-color: $background-color_2;
	}
}
.button_error {
	background: #e64646;
	&:hover {
		background: #e66969;
	}
}
.error_message {
	position: absolute;
	top: 110%;
	margin: 0px;
	color: $color_3;
	font-size: 14px;
	text-align: center;
	animation: zoom 0.5s;
}
@media screen and (max-width: 750px) {
	.button {
		width: 100%;
	}
}
