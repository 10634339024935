.modal_title {
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
}

.input_title {
  margin: 10px 0px;
}

.button_wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media screen and (max-width: 750px) {
  .input_title {
    font-size: 14px;
  }
}
