.backdrop {
  position: fixed;
  top: 0;
  z-index: 3;
  width: 0;
  height: 0;
  background-color: rgba(0, 0, 0, 0.54);
  opacity: 0;
  transition: width 0ms 0.25s, height 0ms 0.25s, opacity 0.25s;
}

.backdropShow {
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: width 0ms, height 0ms, opacity 0.25s;
}
